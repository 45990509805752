import {ChangeDetectionStrategy, Component, OnInit, signal} from '@angular/core';
import {LanguagesService} from '../../core/services/languages.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ThemeService} from '../../core/services/theme.service';
import {NotificationsService} from '../../core/services/notifications.service';
import {UserLoggedService} from '../../core/services/userLogged.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class HeaderComponent implements OnInit {
  public readonly isDarkTheme$ = this.themeService.isDark$;
  public readonly languages$ = this.languagesService.languages$;
  public readonly currentLanguage$ = this.languagesService.currentLanguage$;
  public readonly notifications$ = this.notificationsService.notifications$;
  public readonly currentUser$ = this.userLoggedService.connectedUser$;
  logoutModalOpen$ = signal<boolean>(false);

  constructor(private readonly themeService: ThemeService,
              private readonly languagesService: LanguagesService,
              private readonly userLoggedService: UserLoggedService,
              private readonly notificationsService: NotificationsService) {

  }

  ngOnInit(): void {
    this.notificationsService.getNotifications()
      .subscribe();
  }

  toggleDarkTheme() {
    this.themeService.update();
  }

  readNotification(notificationId: number) {
    this.notificationsService
      .read(notificationId)
      .subscribe();
  }

  languageChange(language: string) {
    this.languagesService.update(language).subscribe();
  }

  openLogoutModal() {
    this.logoutModalOpen$.set(true);
  }

  closeLogoutModal() {
    this.logoutModalOpen$.set(false);
  }
}
