import {computed, effect, Injectable, Signal} from '@angular/core';
import {UserLoggedService} from './userLogged.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public readonly isDark$: Signal<boolean> = computed(() => {
    return this.userLoggedService.connectedUser$()?.darkTheme || false;
  });

  constructor(private readonly userLoggedService: UserLoggedService) {
    effect(() => {
      const body = document.body;
      if (this.isDark$()) {
        document.body.className = `${body.className} dark`;
      } else {
        document.body.className = body.className.replace(' dark', '');
      }
    });
  }

  update() {
    this.userLoggedService.updateDarkTheme(!this.isDark$())
      .subscribe();
  }
}
