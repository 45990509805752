import {
  HttpInterceptorFn,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';

const isNull = (value: string) => {
  return (
    value === null ||
    value === undefined ||
    value === 'undefined' ||
    value === 'null'
  );
};

export function cleanRequestParams(req: HttpRequest<unknown>) {
  return req.clone({
    params: req.params.keys().reduce((params, key) => {
      const values = req.params.getAll(key);

      values
        .filter((value) => !isNull(value))
        .forEach((v) => (params = params.append(key, v)));

      return params;
    }, new HttpParams()),
  });
}

export const httpParameterCleanerInterceptor: HttpInterceptorFn = (
  req,
  next
) => {
  return next(cleanRequestParams(req));
};
