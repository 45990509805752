<bs-header [isDarkTheme]="isDarkTheme$()"
           [languages]="languages$()"
           [currentLanguage]="currentLanguage$()"
           [notifications]="notifications$()"
           settingsUrl="/me/settings"
           [username]="currentUser$()?.username"
           (languageChange)="languageChange($event)"
           (readNotification)="readNotification($event)"
           (logout)="openLogoutModal()"
           (darkThemeToggle)="toggleDarkTheme()">

    <ng-container navItems>
        <bs-header-nav-item label="GLOBAL.GLOBAL.WORKFLOW" url="workflow"></bs-header-nav-item>
        <bs-header-nav-item label="GLOBAL.GLOBAL.ADMINISTRATION" url="admin"></bs-header-nav-item>
        <bs-header-nav-item label="GLOBAL.GLOBAL.HELP" url="help"></bs-header-nav-item>
    </ng-container>
</bs-header>


<app-easy-modal [modalTitle]="'GLOBAL.GLOBAL.LOG_OUT' | translate"
                [overlayClose]="true"
                [open]="logoutModalOpen$()" [fitWidth]="true"
                (close)="closeLogoutModal()">
    <div class="logout-body">
        {{ "GLOBAL.GLOBAL.LOG_OUT_CONFIRMATION" | translate }}
    </div>
    <div class="logout-footer" footer>
        <bs-button [label]="'COMMON.COMMON.CANCEL' | translate"
                   (click)="closeLogoutModal()">
        </bs-button>
        <bs-button color="danger"
                   [label]="'GLOBAL.GLOBAL.LOG_OUT' | translate" icon="fas
            fa-power-off"
                   routerLink="/logout">
        </bs-button>
    </div>
</app-easy-modal>
